@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


@import "bmwgroup/_fonts.css";
@import "bmwgroup/_complaints.css";
@import "bmwgroup/_password_reset.css";

body {
	line-height: 1.375rem;
	@apply text-default text-base font-regular;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	@apply font-condensed-bold uppercase font-normal;
}

h1, .h1 {
	@apply text-h1;
}

h2, .h2 {
	@apply text-h2;
}

h3, .h3 {
	@apply text-h3;
}

h4, .h4 {
	@apply text-h4;
}

p a {
	@apply font-regular font-bold underline transition hover:text-grey;
}

strong {
	@apply font-condensed-bold;
}


.brand {
	height: 38px;
}

.button {
	@apply h-[56px] pl-6 pr-10 text-white inline-flex items-center relative font-condensed-bold max-w-[378px] transition-all;
}

.container, .sm\:container {
	max-width: 1444px !important;
}


table {

    th {
        @apply text-left;
    }

    th, td {
        @apply p-2 border border-[#cecece];
    }

}


label {
	@apply block mt-2;
}

input[type="text"], input[type="password"] {
	@apply border mt-1 px-4 py-2 w-[400px];
}

button[type="submit"], input[type="submit"] {
	@apply mt-8 cursor-pointer bg-default hover:bg-black transition text-center px-8 py-4 text-white inline-block font-condensed-bold w-[400px];
}


label:has(input[type="checkbox"]) {

	@apply flex items-start relative;
	@apply text-base font-condensed-bold font-bold normal-case cursor-pointer pl-8;
	@apply before:content-[""] before:border before:border-default before:w-6 before:h-6 before:absolute before:left-0 before:top-0;

	input {
		@apply hidden;
	}

	&:has(input[type="checkbox"]:checked) {
		@apply before:content-[""] before:absolute before:left-0 before:top-0 before:h-6 before:w-6;
		@apply after:content-[""] after:absolute after:h-[6px] after:w-[10px] after:top-[8px] after:left-[7px];
		@apply after:border-default after:border-b-2 after:border-l-2 after:-rotate-45;
	}

	&:has(input[type="checkbox"][disabled]) {
		@apply  before:border-grey;
	}

}
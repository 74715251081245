@font-face {
	font-family:"BMWGroupSerif-Light";
	src:url("../fonts/bmwgroup/BMWGroupTNSemiSfCnProTT-Light.woff") format("woff")
}
@font-face {
	font-family:"BMWGroupTNProTT-Regular";
	src:url("../fonts/bmwgroup/BMWGroupTNProTT-Regular.woff") format("woff")
}
@font-face {
	font-family:"BMWGroupTNProTT-Bold";
	src:url("../fonts/bmwgroup/BMWGroupTNProTT-Bold.woff") format("woff")
}
@font-face {
	font-family:"BMWGroupTNProTT-Light";
	src:url("../fonts/bmwgroup/BMWGroupTNProTT-Light.woff") format("woff")
}
@font-face {
	font-family:"BMWGroupTNCondensedProTT-Regular";
	src:url("../fonts/bmwgroup/BMWGroupTNCondensedProTT-Regular.woff") format("woff")
}
@font-face {
	font-family:"BMWGroupTNCondensedProTT-Bold";
	src:url("../fonts/bmwgroup/BMWGroupTNCondensedProTT-Bold.woff") format("woff")
}
@font-face {
	font-family:"bmw-group-ci-2015-icons";
	src:url("../fonts/bmwgroup/bmw-group-ci-2015-icons.woff") format("woff")
}
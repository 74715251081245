.status--new {
    @apply font-condensed-bold;
}

.age--2, .age--3, .age--4 {

    @apply bg-1585-c bg-opacity-30;
}

.age--5 {
    @apply bg-error bg-opacity-30;
}

.status--answered {
    @apply font-condensed !bg-white;
}